/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as queries from "../../../operations/queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SvgIcon from "@mui/material/SvgIcon";
import * as mutations from "../../../operations/mutations";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";
import { useParams } from "react-router";
import { CountryContext, mainContext } from "../MainComponent";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
// import ReactStars from "react-rating-star-with-type";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Helmet } from "react-helmet";
import {
  CardActionArea,
  CardContent,
  CardMedia,
  Input,
  ListItemIcon,
  Skeleton,
} from "@mui/material";
// import PostpayWidget from "./postpayStrip/postPayStrip";
import TabbyProductWidget from "./postpayStrip/tabbyProduct";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useMutation } from "@apollo/client";
import theme from "../../../theme";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "@mui/material/Card";
import Addcart from "../Layout/addcart";
import soldByThumbImg from "../../../NewAssets/image/thumb_rating.png";
import ProductOffersPopUp from "./productOffers/productOffersPopUp";
import AddReviewModal from "./AddReviewModal";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
//import PreOrderImageEn from "../../../NewAssets/image/pre_order.png";
import PreOrderImageEN from "../../../NewAssets/image/pre_order_en.png";
import PreOrderImageAR from "../../../NewAssets/image/pre_order_ar.png";
import PlusMinusImage from "../../../NewAssets/image/plus-minus.png";
import NotFoundPage from "../../../components/404page/NotFoundPage";
import { toast } from "react-toastify";
import DetailsPageSkelton from "../../../components/skelton/DetailsPageSkelton";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1200, min: 900 },
    items: 4,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 900, min: 600 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#439840" : "#F2F0F0",
  },
}));

const detailsSlider = {
  mobileSlider: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};
const qtyOptions = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: "custom", label: "Custom" },
];
function CustomSelectIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
        fill="#000"
        fillOpacity="0.51"
      />
    </SvgIcon>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  detailsData: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, detailsData, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      {detailsData?.top_badge_left && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: 10,
          }}
        >
          <img
            src={detailsData?.top_badge_left}
            alt={detailsData?.top_badge_left_title || "Badge"}
            style={{
              width: "150px",
              height: "auto",
              marginTop: "-42px",
            }}
          />
        </div>
      )}

      {/* Content of TabPanel */}
      {value === index && (
        <Box sx={{ p: 3, flex: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface sellerDataObjProp {
  __typename: string;
  qty: number;
  price: number;
  currency: string;
  seller_id: number;
  seller_name: string;
  delivery_note: string;
  warranty: string;
}
type sellerDataArrProp = sellerDataObjProp[];

function DetailsPage(props: any, { customerId }: any) {
  // document.body.classList.add("product-page");
  const loggedInToken = localStorage.getItem("loggedInCust");
  const userLoggedIn = localStorage.getItem("LoggedInUser");
  const wishlistId = localStorage.getItem("wishlistId");
  const location = useLocation();
  const { getCartId } = props;
  const { country, store } = useContext(CountryContext);
  const ratingCardRef = useRef<HTMLDivElement>(null);
  const {
    setCartData,
    cartCount,
    setCartCount,
    quest_cart_id,
    set_quest_cart_id,
    wishlistData,
    setIsAdded,
    isAdded,
  } = useContext(mainContext);
  const { t } = useTranslation();
  const [rvalue, setRtabValue] = React.useState(0);
  const [lvalue, setLtabValue] = useState(0);
  const skeletonArr = Array.from({ length: 10 });
  let galleryImage: any = [];
  let smallImage: any = [];
  let variantsDetails: any = "";
  // const [variantsDetails, setVariantDetails] = useState("");
  let colorVariation: any = "";
  let lableVariation: any = "";
  let cartId: any = quest_cart_id || "";
  const params = useParams();
  const urlKey = useMemo(() => {
    if (params.urlKey) {
      return params.urlKey.split(".html");
    }
    return [];
  }, [params.urlKey]);
  const fullUrl = window.location.href;
  const [proQty, setProQty] = React.useState("1");
  const [tempCartId, setTempCartId] = React.useState(cartId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userErrorsCart, setUserErrorsCart] = useState("");
  const [addingToCart, setAddingToCart] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [displayWasPrice, setDisplayWasPrice] = useState(false);
  const [showCustomInput, setShowCustomInput] = useState<boolean>(false);
  const [isOpenMobileDrawer, setIsOpenMobileDrawer] = useState<boolean>(false);
  const [currentCartId, setCurrentCartId] = useState();
  const [currentStore, setCurrentStore] = useState();
  const [showProductOffers, setShowProductOffers] = useState(false);
  const [sellerDatas, setSellerDatas] = useState<sellerDataArrProp>([]);
  // eslint-disable-next-line
  const [sellerIdfromChild, setSellerIdFromChild] = useState(null);
  const [showAddReviewModal, setShowAddReviewModal] = useState(false);
  const [sellersName, setSellersName] = useState("");
  const [preOrderImg, setPreOrderImg] = useState(PreOrderImageEN);
  // eslint-disable-next-line
  const [preOrder, setPreOrder] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [preOrderDeliveryDate, setPreOrderDeliveryDate] = useState("");
  const [wishlistProductId, setwishlistProductId] = useState();
  const [selectedGrinder, setSelectedGrinder] = useState<string>("");
  const [bagSizes, setBagSizes] = useState<string[]>([]);
  const [selectedBagSize, setSelectedBagSize] = useState<string>("");
  const [currentVariant, setCurrentVariant] = useState<any>(null);

  const handleAddReviewOpen = () => {
    setShowAddReviewModal(true);
  };
  const handleAddReviewClose = () => {
    setShowAddReviewModal(false);
  };

  const handleSellerIdChange = (sellerIdfromChild: any) => {
    setSellerIdFromChild(sellerIdfromChild);
  };

  const toggleProductOffers = () => {
    setShowProductOffers(!showProductOffers);
  };

  const closeProductOffers = () => {
    setShowProductOffers(false);
  };

  let storedDataString: string | null = sessionStorage.getItem("storeAndCart");

  let storeAndCartData: any[] = storedDataString
    ? JSON.parse(storedDataString)
    : [];

  const toggleDrawer = (isOpen: boolean) => () => {
    setIsOpenMobileDrawer(isOpen);
  };

  const [
    fetchDetailsData,
    {
      loading: detailsloader,
      data: detailsdata,
      refetch: refetchProdDetailsData,
    },
  ] = useLazyQuery(queries.detailsQueries.GET_CONFDETAILS, {
    fetchPolicy: "network-only",
    variables: { url_key: urlKey && urlKey[0] },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const { productData } = location.state || {};
  const detailsData =
    productData?.url_key === urlKey[0]
      ? productData
      : detailsdata?.products?.items[0];

  const RegularPrice =
    variantsDetails && variantsDetails !== undefined
      ? variantsDetails?.price_range?.minimum_price?.regular_price?.value
      : detailsData?.price_range?.minimum_price?.regular_price?.value;
  const SellingPrice =
    variantsDetails && variantsDetails !== undefined
      ? variantsDetails?.price_range?.minimum_price?.final_price?.value
      : detailsData?.price_range?.minimum_price?.final_price?.value;
  console.log("RegularPrice", RegularPrice, SellingPrice);
  const AmountOff = ((RegularPrice - SellingPrice) / RegularPrice) * 100;
  const AmountOffFormatted = Math.round(AmountOff);

  useEffect(() => {
    if (productData?.url_key !== urlKey[0]) {
      fetchDetailsData();
      refetchProdDetailsData();
    }
    //eslint-disable-next-line
  }, [loggedInToken, userLoggedIn, fetchDetailsData, urlKey, store]);

  useEffect(() => {
    if (
      wishlistData?.some(
        (item: any) => item.product.url_key === detailsData?.url_key
      )
    ) {
      setIsAdded(true);
    } else {
      setIsAdded(false);
    }
    //eslint-disable-next-line
  }, [detailsData, wishlistData]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    wishlistData?.filter((item: any) => {
      if (item.product.url_key === detailsData?.url_key) {
        setwishlistProductId(item?.id);
      } else {
        console.log("No matched item found");
      }
    });
  }, [wishlistData, detailsData]);

  useEffect(() => {
    // refetchProData();
    store.match("ar")
      ? setPreOrderImg(PreOrderImageAR)
      : setPreOrderImg(PreOrderImageEN);
  }, [store]);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  const { loading: relatedProductLoader, data: relatedProductData } = useQuery(
    queries.detailsQueries.GET_RELATED_PRODUCT_DETAILS,
    {
      variables: { url_key: urlKey && urlKey[0] },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const RelatedProductData =
    relatedProductData?.products?.items?.[0]?.related_products || [];

  const [fetchCartDetails, { data: cartDetailsRes, refetch: getCartRefetch }] =
    useLazyQuery(queries.cartQueries.GET_CART_DETAILS, {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          Store: store,
        },
      },
    });

  useEffect(() => {
    if (quest_cart_id && quest_cart_id !== "") {
      fetchCartDetails({
        variables: { cart_id: quest_cart_id },
      });
    }
  }, [quest_cart_id, fetchCartDetails, cartCount]);

  const variants = detailsData?.variants || [];

  const groupedVariants = variants?.reduce((acc: any, variant: any) => {
    const grinderType = variant?.attributes?.find(
      (attr: any) => attr.code === "grinder_type"
    )?.label;

    const bagSize = variant?.attributes?.find(
      (attr: any) => attr.code === "bag_size"
    )?.label;

    if (grinderType && bagSize) {
      if (!acc[grinderType]) acc[grinderType] = [];
      if (!acc[grinderType].includes(bagSize)) acc[grinderType].push(bagSize);
    }
    return acc;
  }, {});

  useEffect(() => {
    const initialGrinderType = Object.keys(groupedVariants)?.[0] || "";
    if (initialGrinderType !== selectedGrinder) {
      setSelectedGrinder(initialGrinderType);
    }

    const initialBagSizes = groupedVariants[initialGrinderType] || [];
    if (JSON.stringify(initialBagSizes) !== JSON.stringify(bagSizes)) {
      setBagSizes(initialBagSizes);
    }

    const initialBagSize = groupedVariants[initialGrinderType]?.[0] || "";
    if (initialBagSize !== selectedBagSize) {
      setSelectedBagSize(initialBagSize);
    }
    // eslint-disable-next-line
  }, [variants]);

  useEffect(() => {
    setBagSizes(groupedVariants[selectedGrinder] || []);
    setSelectedBagSize(groupedVariants[selectedGrinder]?.[0] || "");
    //eslint-disable-next-line
  }, [selectedGrinder]);

  useEffect(() => {
    const variant = detailsData?.variants?.find((variant: any) => {
      const grinderType = variant.attributes.find(
        (attr: any) => attr.code === "grinder_type"
      )?.label;

      const bagSize = variant.attributes.find(
        (attr: any) => attr.code === "bag_size"
      )?.label;

      return grinderType === selectedGrinder && bagSize === selectedBagSize;
    });

    setCurrentVariant(variant || null);
    //eslint-disable-next-line
  }, [selectedGrinder, selectedBagSize, currentVariant]);

  const handleGrinderChange = (event: any) => {
    setSelectedGrinder(event.target.value);
  };

  const handleBagSizeClick = (size: string) => {
    setSelectedBagSize(size);
  };

  const { data: dataSeller } = useQuery(
    queries.productSeller.GET_PRODUCT_DETAILS,
    {
      variables: { url_key: urlKey && urlKey[0] },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const sellerData = dataSeller?.products?.items[0];

  useEffect(() => {
    const sellerData = dataSeller?.products?.items[0];
    if (sellerData && sellerData.sellerData) {
      setSellerDatas(sellerData.sellerData as sellerDataArrProp);
    }
  }, [dataSeller, sellerDatas]);

  useEffect(() => {
    if (sellerDatas && sellerDatas.length > 0) {
      const name = sellerDatas[0]?.seller_name || "Jebelz.com";
      setSellersName(name);
    } else {
      setSellersName("Jebelz.com");
    }
  }, [sellerDatas]);

  const sellerName = sellersName;
  const sellerColor = "#00B4D7";
  const primarySellerHtml = `${t(
    "inner_pages.detail_page.sold-by"
  )} : <span style="color: ${sellerColor}; font-weight: 500;">${sellerName}</span>`;

  const { data: ratingsData } = useQuery(
    queries.ratingQueries.GET_RATINGS_METADATA,
    {
      variables: { url_key: urlKey && urlKey[0] },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [addProductsToWishlist] = useMutation(
    mutations.addProductToWishlist.ADD_PRODUCTS_TO_WISHLIST,
    {
      onCompleted: () => {
        toast.success(t("inner_pages.detail_page.wishlist_sucs_toast"));
        setIsAdded(true);
      },
      onError: (err) => {
        toast.error(t("inner_pages.detail_page.wishlist_err_toast"));
      },
    }
  );

  const handleWishlistClick = () => {
    if (!loggedInToken) {
      toast.warning(t("inner_pages.detail_page.login_toast"));
      return;
    }

    if (wishlistId) {
      if (cartId) {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          addProductsToWishlist({
            variables: {
              wishlistId: parseInt(wishlistId),
              wishlistItems: [
                {
                  parent_sku: productData?.sku,
                  sku: childSkuName,
                  quantity: parseInt(proQty),
                },
              ],
            },
            context: {
              headers: {
                Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
                Connection: "keep-alive",
                store: store,
              },
            },
          });
        } else {
          addProductsToWishlist({
            variables: {
              wishlistId: parseInt(wishlistId),
              wishlistItems: [
                {
                  sku: productData?.sku,
                  quantity: parseInt(proQty),
                },
              ],
            },
            context: {
              headers: {
                Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
                Connection: "keep-alive",
                store: store,
              },
            },
          });
        }
      }
    } else {
      toast.error(t("inner_pages.detail_page.no_wishlist_toast"));
    }
  };

  const [removeProductsToWishlist] = useMutation(
    mutations.addProductToWishlist.REMOVE_PRODUCTS_FROM_WISHLIST,
    {
      onCompleted: () => {
        toast.success(t("inner_pages.detail_page.product_removed_toast"));
        setIsAdded(false);
      },
      onError: (err) => {
        toast.error(t("inner_pages.detail_page.product_removed_toast_err"));
      },
    }
  );

  const handleRemoveWishlistClick = () => {
    const parsedWishlistId = wishlistId ? parseInt(wishlistId, 10) : NaN;

    if (wishlistId) {
      removeProductsToWishlist({
        variables: {
          wishlistId: parsedWishlistId,
          wishlistItemsIds: wishlistProductId
            ? [parseInt(wishlistProductId)]
            : "",
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      });
    } else {
      toast.error("No wishlist found. Please try again.");
    }
  };

  // console.log("------ ratingsData ----", ratingsData?.productReviewRatingsMetadata?.items[0])
  const ratingMetaDataId =
    ratingsData?.productReviewRatingsMetadata?.items[0]?.id;
  const ratingMetadata = () => {
    if (ratingsData?.productReviewRatingsMetadata?.items[0]?.values) {
      return ratingsData?.productReviewRatingsMetadata?.items[0]?.values;
    } else {
      return [];
    }
  };

  useMemo(() => {
    if (
      cartDetailsRes &&
      cartDetailsRes?.cart &&
      cartDetailsRes?.cart?.items?.length
    ) {
      setCartCount(cartDetailsRes?.cart?.items?.length);
      set_quest_cart_id(tempCartId);
      setCartData(cartDetailsRes?.cart?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartDetailsRes]);
  if (groupedVariants && Object.keys(groupedVariants).length > 0) {
    variantsDetails = currentVariant?.product;
  } else if (detailsData?.variants && detailsData?.variants.length > 0) {
    variantsDetails = detailsData?.variants[lvalue]?.product;
    colorVariation = detailsData?.variants[lvalue]?.attributes[0]?.label;
    lableVariation = detailsData?.variants[lvalue]?.attributes[0]?.code;
  }

  let childSkuName =
    variantsDetails && variantsDetails !== undefined
      ? variantsDetails?.sku
      : detailsData?.sku;

  if (detailsData?.variants && detailsData?.variants.length > 0) {
    // eslint-disable-next-line array-callback-return
    detailsData?.variants?.map((items: any, index: any) => {
      smallImage.push(
        <div className="tab-box">
          <img
            src={items?.product?.small_image?.url}
            alt={items?.product?.small_image?.label}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      );
    });
  } else {
    smallImage = (
      <div className="tab-box">
        <img
          className="img-cover"
          src={detailsData?.small_image?.url}
          alt={detailsData?.small_image?.label}
        />
      </div>
    );
  }
  if (detailsData?.variants && detailsData?.variants.length > 0) {
    // eslint-disable-next-line array-callback-return
    detailsData?.variants?.map((items: any, index: any) => {
      galleryImage.push(
        <div className="tab-box">
          <img
            src={items?.product?.media_gallery[index]?.thumbnail_url}
            alt={`${items?.product?.name}-${index + 1}`}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      );
    });
  } else {
    // eslint-disable-next-line array-callback-return
    detailsData?.media_gallery?.map((items: any, index: any) => {
      galleryImage.push(
        <div className="tab-box">
          <img
            src={items?.thumbnail_url}
            alt={`${items?.product?.name}-${index + 1}`}
          />
        </div>
      );
    });
  }
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const description = detailsData?.short_description?.html;
  const breadcrumb = detailsData?.breadcrumb;
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setRtabValue(newValue);
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setLtabValue(newValue);
  };

  const handledQtyChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    if (selectedValue === "custom") {
      setShowCustomInput(true);
      setProQty("");
    } else {
      setShowCustomInput(false);
      setProQty(selectedValue);
    }
  };

  const [addProductsToCart] = useMutation(
    mutations.addToCartMutation.ADD_SIMPLE_CART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [createProductReview] = useMutation(
    mutations.createProductReview.CREATE_PRODUCT_REVIEW,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [guestCart] = useMutation(mutations.addToCartMutation.GUEST_CART_DATA, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const [loggedUserCart] = useMutation(
    mutations.addToCartMutation.LOGGUSER_CART_DATA,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleFormSubmit = async (reviewData: any) => {
    // handleAddReviewClose();
    try {
      // eslint-disable-next-line
      const { data } = await createProductReview({
        variables: {
          input: {
            ...reviewData,
          },
        },
      });
      toast.success(t("inner_pages.detail_page.review_toast"));
      handleAddReviewClose();
      // await refetchProData();
    } catch (error) {
      console.error("Error creating product review:", error);
    }
  };

  const addSimpleCart = (finalisedSellerId: any) => {
    return new Promise((resolve) => {
      addProductsToCart({
        variables: {
          // cartId: 'AdGvg2DjN1YedY5ZgoyIQvBJ8xHs0Ptq',
          cartId: cartId,
          quantity: proQty,
          sku: detailsData?.sku,
          seller_id: finalisedSellerId,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      })
        .then((response) => {
          /* setUserErrorsCart(
                 response?.data?.addProductsToCart?.user_errors[0]?.code
               ); */
          getCartId(cartId);
          resolve(true);
          /* if (response?.data?.addProductsToCart?.user_errors.length === 0) {
                 setAddedToCart(true);
               } */
          setAddedToCart(true);
        })
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  };

  const [addConfigurableProductsToCart] = useMutation(
    mutations.addToCartMutation.ADD_CONFIG_PRODUCTS_TO_CART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleConfigProdCart = async (finalisedSellerId: any) => {
    return new Promise(async (resolve) => {
      try {
        await addConfigurableProductsToCart({
          variables: {
            input: {
              cart_id: cartId,
              cart_items: [
                {
                  parent_sku: detailsData?.sku,
                  data: {
                    quantity: proQty,
                    sku: childSkuName,
                    seller_id: finalisedSellerId,
                  },
                },
              ],
            },
          },
          context: {
            headers: {
              Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
              Connection: "keep-alive",
              store: store,
            },
          },
        }).then((res: any) => {
          setAddedToCart(true);
        });
        resolve(true);
        getCartId(cartId);
      } catch (error) {
        resolve(false);
        console.error(error);
      }
    });
  };

  const [fetchQueryId, { data: custQueryDataRes }] = useLazyQuery(
    queries.customerQueries.GET_CUSTOMER_CART_QUERY,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  useEffect(() => {
    if (loggedInToken) {
      fetchQueryId();
    }
  }, [loggedInToken, userLoggedIn, fetchQueryId]);

  useEffect(() => {
    const sessionStoreDataVal = sessionStorage.getItem("storeAndCart");
    if (sessionStoreDataVal) {
      try {
        const storeAndCartArray = JSON.parse(sessionStoreDataVal);
        if (Array.isArray(storeAndCartArray) && storeAndCartArray.length > 0) {
          const filteredArray = storeAndCartArray?.filter(
            (object) => object.genStore === store
          );
          filteredArray?.forEach((item) => {
            setCurrentStore(item.genStore);
            setCurrentCartId(item.sessionGenCartId);
          });
        } else {
          console.error("sessionStoreDataVal is not an array or is empty");
        }
      } catch (error) {
        console.error("Error parsing sessionStoreDataVal:", error);
      }
    } else {
      console.log("sessionStoreDataVal is null or undefined");
    }
  }, [cartId, store]);

  useEffect(() => {
    set_quest_cart_id(cartId);
    // localStorage.setItem("quest_cart_id", cartId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custQueryDataRes]);
  //const primary_sellerId = detailsData.sellerData[0].seller_id

  const handleAddToCart = async (sellerId: any) => {
    setAddingToCart(true);
    if (loggedInToken && loggedInToken !== "") {
      if (custQueryDataRes && custQueryDataRes?.customerCart?.id !== "") {
        cartId = custQueryDataRes?.customerCart?.id;
        // localStorage.setItem("quest_cart_id", cartId);
        set_quest_cart_id(cartId);
      } else {
        loggedUserCart({})
          .then(async (response) => {
            if (response?.data && response?.data?.createEmptyCart) {
              cartId = response?.data?.createEmptyCart;
              // localStorage.setItem("quest_cart_id", cartId);
              set_quest_cart_id(cartId);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (cartId) {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          await handleConfigProdCart(sellerId);
        } else {
          await addSimpleCart(sellerId);
        }
        getCartRefetch();
      }
    } else {
      if (cartId && cartId !== "undefined") {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          await handleConfigProdCart(sellerId);
        } else {
          await addSimpleCart(sellerId);
        }
        getCartRefetch();
      } else {
        guestCart({})
          .then(async (response) => {
            if (
              (currentCartId === undefined && currentStore === undefined) ||
              currentStore !== store
            ) {
              if (response?.data && response?.data?.createEmptyCart) {
                cartId = response.data.createEmptyCart;
                // localStorage.setItem("quest_cart_id", cartId);
                const sessionObject = {
                  sessionGenCartId: cartId,
                  genStore: store,
                };
                storeAndCartData.push(sessionObject);
                const sessionObjectData: any = JSON.stringify(storeAndCartData);
                sessionStorage.setItem("storeAndCart", sessionObjectData);
                set_quest_cart_id(cartId);
                if (detailsData?.variants && detailsData?.variants.length > 0) {
                  await handleConfigProdCart(sellerId);
                } else {
                  await addSimpleCart(sellerId);
                }

                setTempCartId(cartId);
              } else {
                console.error("Invalid response data structure");
              }
            } else {
              set_quest_cart_id(currentCartId);
              cartId = currentCartId;
              if (detailsData?.variants && detailsData?.variants.length > 0) {
                await handleConfigProdCart(sellerId);
              } else {
                await addSimpleCart(sellerId);
              }

              setTempCartId(cartId);
            }
            getCartRefetch();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    setAddingToCart(false);
    setIsOpenMobileDrawer(true);
  };

  useEffect(() => {
    if (variantsDetails && variantsDetails !== undefined) {
      if (
        variantsDetails?.price_range?.minimum_price?.regular_price?.value !==
        variantsDetails?.price_range?.minimum_price?.final_price?.value
      ) {
        setDisplayWasPrice(true);
      }
    } else {
      if (
        detailsData?.price_range?.minimum_price?.regular_price?.value !==
        detailsData?.price_range?.minimum_price?.final_price?.value
      ) {
        setDisplayWasPrice(true);
      }
    }
    // eslint-disable-next-line
    let preOrderCheck = detailsData?.attributes?.map((row: any, index: any) =>
      row?.code === "pre_order_delivery_date" ? setPreOrder(true) : ""
    );
    // eslint-disable-next-line
    let preOrderCheckDate = detailsData?.attributes?.map(
      (row: any, index: any) =>
        row?.code === "pre_order_delivery_date"
          ? setPreOrderDeliveryDate(row?.value)
          : ""
    );
  }, [variantsDetails, detailsData]);

  const downloadPdf = async (item: any) => {
    try {
      const pdfUrl = item.url;
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("Error opening PDF:", error);
    }
  };

  const reviewsArr = detailsData?.reviews?.items;

  const countRatingOccurrences = () => {
    const ratingCounts: { [key: number]: number } = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };
    detailsData?.reviews?.items.length &&
      reviewsArr.forEach((review: any) => {
        const ratingValue = review?.ratings_breakdown[0]?.value as number;
        ratingCounts[ratingValue]++;
      });
    return ratingCounts;
  };

  const ratingCounts = countRatingOccurrences();

  const ratingsOutOf5: number[] =
    detailsData?.reviews?.items.length &&
    reviewsArr?.map((review: any) => review.average_rating / 20);
  const averageRating: number =
    detailsData?.reviews?.items.length &&
    ratingsOutOf5.reduce((total: number, rating: number) => total + rating, 0) /
      ratingsOutOf5.length;
  const finalAverageRating: number = Math.round(averageRating * 10) / 10;

  const handleSimilarProduct = (urlKey: any) => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("/").slice(0, -1).join("/") + "/";
    const newUrl = baseUrl + urlKey + ".html";
    window.location.href = newUrl;
  };

  const extractProductName = () => {
    return variantsDetails && variantsDetails !== undefined
      ? variantsDetails?.name
      : detailsData?.name;
  };

  const extractProductImage = () => {
    return variantsDetails && variantsDetails !== undefined
      ? variantsDetails?.media_gallery[0]?.thumbnail_url
      : detailsData?.image.url;
  };

  const handleScrollToRating = () => {
    if (ratingCardRef.current) {
      ratingCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {detailsloader ? (
        <DetailsPageSkelton propsDetails="detailsPage" />
      ) : (
        <div className="details-wrapper">
          <Helmet>
            <title>{detailsData?.meta_title}</title>
            <meta name="description" content={detailsData?.meta_description} />
            <meta name="keywords" content={detailsData?.meta_keyword} />
            <meta name="DC.title" content={detailsData?.meta_title} />
            <meta
              name="geo.region"
              content={store === "in" ? "IN-UP" : "AE-DU"}
            />
            <meta
              name="geo.placename"
              content={store === "in" ? "Noida" : "Dubai"}
            />
            <meta
              name="geo.position"
              content={
                store === "in" ? "28.58508;77.315262" : "25.121614;55.377031"
              }
            />
            <meta
              name="ICBM"
              content={
                store === "in" ? "28.58508, 77.315262" : "25.121614, 55.377031"
              }
            />
            <meta property="og:title" content={detailsData?.meta_title} />
            <meta
              property="og:description"
              content={detailsData?.meta_description}
            />
            <meta property="og:url" content={fullUrl} />
            <meta property="og:image" content={detailsData?.image?.small_url} />
            <meta property="og:type" content={detailsData?.sku} />
          </Helmet>
          {/* <Button variant="text" onClick={handleConfigProdCart}>Text</Button> */}
          {detailsData !== undefined ? (
            <>
              <div style={{ backgroundColor: "#fff", marginBottom: "20px" }}>
                <Container maxWidth={false}>
                  <Box sx={{ pt: 2 }}>
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <div className="breadcrumb-section">
                          <div
                            style={{
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              direction:
                                store === "ae_ar" ||
                                store === "sa_ar" ||
                                store === "ar"
                                  ? "rtl"
                                  : "ltr",
                              textAlign:
                                store === "ae_ar" ||
                                store === "sa_ar" ||
                                store === "ar"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            <Breadcrumbs
                              separator={<NavigateNextIcon fontSize="small" />}
                              aria-label="breadcrumb"
                              className="breadcrumb-data"
                            >
                              <div
                                dangerouslySetInnerHTML={{ __html: breadcrumb }}
                              />
                            </Breadcrumbs>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ pt: 2, pb: 3 }}>
                    <Grid container className="directionrtl">
                      <Grid item xl={4} lg={4} md={7} sm={12} xs={12}>
                        <Item sx={{ boxShadow: 0, height: "100%" }}>
                          <div className="right-img-tab cust-tabs-list">
                            {!isMobileScreen ? (
                              <Box
                                className="cust-tabs-view-img directionrtl"
                                sx={{
                                  flexGrow: 1,
                                  bgcolor: "background.paper",
                                  display: "flex",
                                  height: 550,
                                }}
                              >
                                {detailsData?.variants &&
                                detailsData?.variants?.length > 0 ? (
                                  <>
                                    <Tabs
                                      sx={{ width: "140px", overflow: "auto" }}
                                      className="right-tabs"
                                      orientation="vertical"
                                      variant="scrollable"
                                      value={rvalue}
                                      // onChange={handleChange}
                                      aria-label="Media gallery tabs "
                                      // sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                      {detailsData?.variants[
                                        lvalue
                                      ]?.product?.media_gallery?.map(
                                        (items: any, index: any) => {
                                          return (
                                            <Tab
                                              {...a11yProps(index)}
                                              key={index}
                                              label={
                                                <div className="tab-box">
                                                  <img
                                                    src={items?.thumbnail_url}
                                                    alt={`${
                                                      detailsData?.name
                                                    }-${index + 1}`}
                                                    style={{
                                                      height: "100%",
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>
                                              }
                                              onMouseEnter={(e) =>
                                                handleChange(e, index)
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </Tabs>

                                    <TabPanel
                                      value={rvalue}
                                      index={rvalue}
                                      detailsData={detailsData}
                                    >
                                      <ReactImageMagnify
                                        {...{
                                          className: "-magnifyimg",
                                          smallImage: {
                                            alt: `${detailsData?.variants[lvalue]?.product?.name}`,
                                            isFluidWidth: true,
                                            // width: 300,
                                            src: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`
                                              ? `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`
                                              : ``,
                                            srcSet:
                                              `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`
                                                ? `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`
                                                : ``,
                                            // src: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url || 'https://access.jebelz.com/media/catalog/product/cache/4ec3cacea31d5a03269462614fb10cbb/x/5/x54_front_1__1.jpg'}`,
                                            // srcSet: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url || 'https://access.jebelz.com/media/catalog/product/cache/4ec3cacea31d5a03269462614fb10cbb/x/5/x54_front_1__1.jpg'}`,
                                            sizes:
                                              "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                                          },
                                          largeImage: {
                                            alt: `${detailsData?.variants[lvalue]?.product?.name}`,
                                            src: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`,
                                            width: 800,
                                            height: 600,
                                          },
                                          enlargedImageContainerStyle: {
                                            zIndex: "1500",
                                            backgroundColor: "white",
                                          },
                                          enlargedImageContainerDimensions: {
                                            width: "100%",
                                            height: "100%",
                                          },
                                          isHintEnabled: true,
                                        }}
                                      />
                                    </TabPanel>
                                  </>
                                ) : (
                                  <>
                                    <Tabs
                                      sx={{ width: "140px" }}
                                      className="right-tabs"
                                      orientation="vertical"
                                      variant="scrollable"
                                      value={rvalue}
                                      onChange={handleChange}
                                      aria-label="Media gallery tabs "
                                      // sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                      {[
                                        ...Array(
                                          detailsData?.media_gallery?.length
                                        ),
                                      ]?.map((items, index) => (
                                        <Tab
                                          {...a11yProps(
                                            detailsData?.media_gallery[index]
                                          )}
                                          key={index}
                                          label={galleryImage[index]}
                                          onMouseEnter={(e) =>
                                            handleChange(e, index)
                                          }
                                        />
                                      ))}
                                    </Tabs>
                                    <TabPanel
                                      value={rvalue}
                                      index={rvalue}
                                      detailsData={detailsData}
                                    >
                                      <ReactImageMagnify
                                        {...{
                                          className: "-magnifyimg",
                                          smallImage: {
                                            alt: `${detailsData?.name}`,
                                            isFluidWidth: true,
                                            // width: 300,
                                            src: `${detailsData?.media_gallery[rvalue]?.url}`,
                                            srcSet: `${detailsData?.media_gallery[rvalue]?.url}`,
                                            sizes:
                                              "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                                          },
                                          largeImage: {
                                            alt: `${detailsData?.name}`,
                                            src: `${detailsData?.media_gallery[rvalue]?.url}`,
                                            width: 800,
                                            height: 600,
                                          },
                                          enlargedImageContainerStyle: {
                                            zIndex: "1500",
                                            backgroundColor: "white",
                                          },
                                          enlargedImageContainerDimensions: {
                                            width: "100%",
                                            height: "100%",
                                          },
                                          isHintEnabled: true,
                                        }}
                                      />
                                    </TabPanel>
                                  </>
                                )}
                              </Box>
                            ) : (
                              <Box>
                                <Carousel
                                  responsive={detailsSlider}
                                  showDots={true}
                                  swipeable={true}
                                  infinite={true}
                                  autoPlay={
                                    detailsData?.media_gallery?.length > 1
                                  }
                                  slidesToSlide={1}
                                  transitionDuration={500}
                                  autoPlaySpeed={5000}
                                  customTransition="all 3s linear"
                                  removeArrowOnDeviceType={["mobileSlider"]}
                                  containerClass="mobile-carousel-container"
                                >
                                  {detailsData?.variants?.length > 0
                                    ? detailsData?.variants[
                                        lvalue
                                      ]?.product?.media_gallery?.map(
                                        (item: any, index: number) => (
                                          <Card
                                            className="popup-slider-card"
                                            key={index}
                                            sx={{
                                              boxShadow: "none",
                                              position: "relative",
                                            }}
                                          >
                                            {/* Badge Image */}
                                            {detailsData?.top_badge_left && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  top: "10px",
                                                  left: "0px",
                                                  zIndex: 10,
                                                }}
                                              >
                                                <img
                                                  src={
                                                    detailsData?.top_badge_left
                                                  }
                                                  alt={
                                                    detailsData?.top_badge_left_title ||
                                                    "Badge"
                                                  }
                                                  style={{
                                                    width: "110px",
                                                    height: "auto",
                                                    marginTop: "-37px",
                                                  }}
                                                />
                                              </div>
                                            )}

                                            {/* Main Image */}
                                            <img
                                              src={item?.url}
                                              alt={`Variant Image ${index + 1}`}
                                              style={{
                                                width: "100%",
                                                // height: "100%",
                                                maxWidth: "295px",
                                              }}
                                            />

                                            {/* Right Icon Slider */}
                                            <div className="right-icon-slider">
                                              <IconButton
                                                aria-label="delete"
                                                size="small"
                                                sx={{
                                                  borderRadius: "5px",
                                                  border: "1px solid #E7E5E5",
                                                  background: "#F8F4F4",
                                                  p: 1,
                                                }}
                                              >
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {isAdded ? (
                                                    <FavoriteIcon
                                                      fontSize="medium"
                                                      style={{ color: "red" }}
                                                      onClick={
                                                        handleRemoveWishlistClick
                                                      }
                                                    />
                                                  ) : (
                                                    <FavoriteBorderOutlinedIcon
                                                      fontSize="medium"
                                                      onClick={
                                                        handleWishlistClick
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </IconButton>
                                            </div>
                                          </Card>
                                        )
                                      )
                                    : detailsData?.media_gallery?.map(
                                        (item: any, index: number) => (
                                          <Card
                                            key={index}
                                            sx={{
                                              boxShadow: "none",
                                              position: "relative",
                                            }}
                                          >
                                            {/* Badge Image */}
                                            {detailsData?.top_badge_left && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  top: "10px",
                                                  left: "0px",
                                                  zIndex: 10,
                                                }}
                                              >
                                                <img
                                                  src={
                                                    detailsData?.top_badge_left
                                                  }
                                                  alt={
                                                    detailsData?.top_badge_left_title ||
                                                    "Badge"
                                                  }
                                                  style={{
                                                    width: "110px",
                                                    height: "auto",
                                                    marginTop: "-37px",
                                                  }}
                                                />
                                              </div>
                                            )}

                                            {/* Main Image */}
                                            <img
                                              src={item?.url}
                                              alt={`Image ${index + 1}`}
                                              style={{
                                                // width: "100%",
                                                height: "100%",
                                                maxHeight: "295px",
                                              }}
                                            />

                                            {/* Right Icon Slider */}
                                            <div className="right-icon-slider">
                                              <IconButton
                                                aria-label="delete"
                                                size="small"
                                                sx={{
                                                  borderRadius: "5px",
                                                  border: "1px solid #E7E5E5",
                                                  background: "#F8F4F4",
                                                  p: 1,
                                                }}
                                              >
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {isAdded ? (
                                                    <FavoriteIcon
                                                      fontSize="medium"
                                                      style={{ color: "red" }}
                                                      onClick={
                                                        handleRemoveWishlistClick
                                                      }
                                                    />
                                                  ) : (
                                                    <FavoriteBorderOutlinedIcon
                                                      fontSize="medium"
                                                      onClick={
                                                        handleWishlistClick
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </IconButton>
                                            </div>
                                          </Card>
                                        )
                                      )}
                                </Carousel>
                              </Box>
                            )}
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                        <Item
                          className="center-content-wrapper"
                          sx={{
                            boxShadow: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Box
                            sx={{ textAlign: "start" }}
                            className="details-center-content"
                          >
                            <div className="top-cart-box"></div>
                            <div
                              className="dtop-img"
                              style={{ maxHeight: "70px" }}
                            >
                              {detailsData?.brand_logo_img && ""}
                              {preOrder ? (
                                <img
                                  src={preOrderImg}
                                  alt="Image not found"
                                  style={{
                                    width: "150px",
                                    display: "flex",
                                    float: store.match("ar") ? "left" : "right",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            {/* {detailsData?.variants.length > 0 ? */}
                            <Stack spacing={2} direction="column">
                              <Link
                                to={`/${store}/${detailsData?.brand_logo_link}`}
                              >
                                <img
                                  style={{ maxHeight: "25px" }}
                                  src={detailsData?.brand_logo_img}
                                  alt={detailsData?.name}
                                />
                              </Link>
                              <Typography
                                variant="h3"
                                component="h1"
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "700",
                                  color: "#000",
                                  marginTop: "0px",
                                }}
                              >
                                {variantsDetails &&
                                variantsDetails !== undefined
                                  ? variantsDetails?.name
                                  : detailsData?.name}
                                {/* {variantsDetails.name : detailsData.name} */}
                              </Typography>
                              <div>
                                <div
                                  className="rating-box"
                                  onClick={handleScrollToRating}
                                >
                                  <Chip
                                    // label={detailsData?.rating_summary}
                                    label={
                                      detailsData?.reviews?.items.length
                                        ? finalAverageRating
                                        : 0
                                    }
                                    icon={
                                      <StarOutlinedIcon className="starIcon" />
                                    }
                                    color="success"
                                  />
                                  <span className="total-rating">
                                    {detailsData?.review_count}{" "}
                                    {t("inner_pages.detail_page.ratings")}
                                  </span>
                                </div>
                              </div>
                              <div className="amount-box">
                                {displayWasPrice && (
                                  <div className="amount-was">
                                    <label>
                                      {t("inner_pages.detail_page.was")}
                                    </label>

                                    <label>
                                      {variantsDetails &&
                                      variantsDetails !== undefined ? (
                                        <>
                                          {
                                            variantsDetails?.price_range
                                              ?.minimum_price?.regular_price
                                              ?.currency
                                          }{" "}
                                          {
                                            variantsDetails?.price_range
                                              ?.minimum_price?.regular_price
                                              ?.value
                                          }
                                        </>
                                      ) : (
                                        <>
                                          {
                                            detailsData?.price_range
                                              ?.minimum_price?.regular_price
                                              ?.currency
                                          }{" "}
                                          {
                                            detailsData?.price_range
                                              ?.minimum_price?.regular_price
                                              ?.value
                                          }
                                        </>
                                      )}
                                    </label>

                                    {AmountOff > 0 && (
                                      <Typography
                                      className="percentage-offIs"
                                    >
                                        {AmountOffFormatted} %{" "}
                                        {t(
                                          "inner_pages.domestic_machine.percentage_off"
                                        )}
                                      </Typography>
                                    )}
                                  </div>
                                )}
                                <div className="amount-now">
                                  <label>
                                    {t("inner_pages.detail_page.now")}
                                  </label>
                                  <Typography variant="h4" component="h4">
                                    {variantsDetails &&
                                    variantsDetails !== undefined ? (
                                      <>
                                        {
                                          variantsDetails?.price_range
                                            ?.minimum_price?.final_price
                                            ?.currency
                                        }{" "}
                                        {
                                          variantsDetails?.price_range
                                            ?.minimum_price?.final_price?.value
                                        }
                                      </>
                                    ) : (
                                      <>
                                        {
                                          detailsData?.price_range
                                            ?.minimum_price?.final_price
                                            ?.currency
                                        }{" "}
                                        {
                                          detailsData?.price_range
                                            ?.minimum_price?.final_price?.value
                                        }
                                      </>
                                    )}
                                    &nbsp;
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        color: "#3D3D3D",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {country === "in"
                                        ? t("main.inclusive_of_gst")
                                        : t("main.inclusive_of_vat")}
                                    </span>
                                  </Typography>
                                </div>

                                <div
                                  className="stock-msg"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    gap: "7px",
                                  }}
                                >
                                  {preOrder ? (
                                    <>
                                      <label>
                                        {t("inner_pages.detail_page.pre-order")}
                                      </label>

                                      <div className="delivr-status-msg">
                                        <label
                                          style={{
                                            float: store.match("ar")
                                              ? "right"
                                              : "left",
                                          }}
                                        >
                                          {t(
                                            "inner_pages.detail_page.delivery_estimation"
                                          )}{" "}
                                          : <span>{preOrderDeliveryDate}</span>
                                        </label>
                                        <span
                                          style={{
                                            backgroundImage: `url(${PlusMinusImage})`,
                                            width: "135px",
                                            height: "55px",
                                            display: "flex",
                                            float: store.match("ar")
                                              ? "right"
                                              : "left",
                                            backgroundSize: "95px",
                                            backgroundRepeat: "no-repeat",
                                            marginTop: "-5px",
                                          }}
                                        ></span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <label
                                        className={`stock_label ${
                                          detailsData?.stock_status ===
                                          "IN_STOCK"
                                            ? "in_stock"
                                            : "out_of_stock"
                                        }`}
                                      >
                                        {detailsData?.stock_status ===
                                        "IN_STOCK"
                                          ? t("inner_pages.detail_page.instock")
                                          : t(
                                              "inner_pages.detail_page.outStock"
                                            )}
                                      </label>
                                      <div className="delivr-status-msg">
                                        <label>
                                          {t(
                                            "inner_pages.detail_page.delivery_estimation"
                                          )}{" "}
                                          :{" "}
                                        </label>
                                        <span>
                                          {detailsData?.delivery_estimation}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>

                              {groupedVariants &&
                                Object.keys(groupedVariants).length > 0 && (
                                  <div className="product-selector directionrtl">
                                    <div className="bag-size-selector">
                                      <label>
                                        {t("inner_pages.detail_page.bag_size")}{" "}
                                        <span>:</span> {selectedBagSize}
                                      </label>
                                      <div className="bag-sizes">
                                        {bagSizes?.map((size, index) => (
                                          <button
                                            key={index}
                                            className={`bag-size-button ${
                                              size === selectedBagSize
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleBagSizeClick(size)
                                            }
                                          >
                                            {size}
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="grinder-type-selector">
                                      <label>
                                        {t(
                                          "inner_pages.detail_page.grinder_type"
                                        )}
                                      </label>
                                      <FormControl fullWidth variant="outlined">
                                        <FormControl variant="outlined">
                                          <Select
                                            id="grinder-select"
                                            value={selectedGrinder}
                                            onChange={handleGrinderChange}
                                            displayEmpty
                                            sx={{
                                              width: "50%",
                                              fontSize: "14px",
                                              color: "#333",
                                              padding: "8px",
                                              borderRadius: "1px",

                                              "& .MuiSelect-select": {
                                                padding: "8px 12px",
                                              },
                                              "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor: "#EEEEEE",
                                                },
                                              "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor: "#EEEEEE",
                                                },
                                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor: "#555",
                                                },
                                            }}
                                          >
                                            {Object.keys(groupedVariants)?.map(
                                              (grinderType) => (
                                                <MenuItem
                                                  key={grinderType}
                                                  value={grinderType}
                                                  sx={{
                                                    fontSize: "14px",
                                                    color: "#5F5E5E",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "#f0f0f0",
                                                    },
                                                    direction: store.match("ar")
                                                      ? "rtl"
                                                      : "ltr",
                                                    textAlign: store.match("ar")
                                                      ? "right"
                                                      : "left",
                                                  }}
                                                >
                                                  {grinderType}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </FormControl>
                                      </FormControl>
                                    </div>
                                  </div>
                                )}
                              <>
                                {userErrorsCart && (
                                  <div
                                    key={userErrorsCart}
                                    className="insufficient-error"
                                  >
                                    <label>{userErrorsCart}</label>
                                  </div>
                                )}
                              </>
                              <label>{t("inner_pages.detail_page.qty")}</label>
                              <div className="qty-box is-mobile-fixed">
                                <Box
                                  sx={{
                                    minWidth: "100%",
                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "end",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: 120,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "5px",
                                      "@media screen and (max-width: 600px)": {
                                        width: 100,
                                      },
                                    }}
                                  >
                                    <FormControl
                                      fullWidth
                                      sx={{
                                        border: "1px solid #C6C3C3",
                                        borderRadius: "2px",
                                      }}
                                    >
                                      {" "}
                                      {showCustomInput ? (
                                        <Input
                                          className="quantity-input-filed"
                                          type="number"
                                          value={proQty}
                                          onChange={(e: any) =>
                                            setProQty(e.target.value)
                                          }
                                          inputProps={{ min: 0 }}
                                          // placeholder="Enter custom quantity"
                                        />
                                      ) : (
                                        <Select
                                          IconComponent={CustomSelectIcon}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          className="qty-select"
                                          value={
                                            detailsData?.stock_status ===
                                            "OUT_OF_STOCK"
                                              ? "0"
                                              : proQty
                                          }
                                          label="Quantity"
                                          onChange={handledQtyChange}
                                          input={<Input />}
                                        >
                                          {qtyOptions?.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      )}
                                    </FormControl>
                                  </Box>
                                  <Button
                                    className="add-to-cart-btn"
                                    onClick={() => {
                                      if (
                                        sellerData &&
                                        sellerData?.sellerData &&
                                        sellerData.sellerData.length > 0
                                      ) {
                                        handleAddToCart(
                                          sellerData?.sellerData[0]?.seller_id
                                        );
                                      } else {
                                        handleAddToCart(0);
                                      }
                                    }}
                                    variant="contained"
                                    startIcon={
                                      <LocalMallOutlinedIcon
                                        style={{
                                          float: store.match("ar")
                                            ? "left"
                                            : "right",
                                          marginLeft: "10px",
                                        }}
                                      />
                                    }
                                    size="large"
                                    disabled={
                                      detailsData?.stock_status ===
                                      "OUT_OF_STOCK"
                                        ? true
                                        : false
                                    }
                                    sx={{
                                      textTransform: "capitalize",
                                      color: "#fff",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      padding: "6px 34px",
                                      width: "100%",
                                    }}
                                  >
                                    {preOrder
                                      ? addingToCart
                                        ? t("inner_pages.detail_page.adding")
                                        : addedToCart
                                        ? t("inner_pages.detail_page.added")
                                        : t("inner_pages.detail_page.pre-order")
                                      : addingToCart
                                      ? t("inner_pages.detail_page.adding")
                                      : addedToCart
                                      ? t("inner_pages.detail_page.added")
                                      : t(
                                          "inner_pages.detail_page.add_to_cart"
                                        )}
                                  </Button>
                                  {/* <Addcart isOpenPopup={openPopup} isAnchor={anchor} handleClosePopup={handleClosePopup}/> */}
                                  {!isMobileScreen ? (
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      sx={{
                                        borderRadius: "5px",
                                        border: "1px solid #E7E5E5",
                                        background: "#F8F4F4",
                                        p: 1,
                                      }}
                                    >
                                      <div style={{ cursor: "pointer" }}>
                                        {isAdded ? (
                                          <FavoriteIcon
                                            fontSize="medium"
                                            style={{ color: "red" }}
                                            onClick={handleRemoveWishlistClick}
                                          />
                                        ) : (
                                          <FavoriteBorderOutlinedIcon
                                            fontSize="medium"
                                            onClick={handleWishlistClick}
                                          />
                                        )}
                                      </div>
                                    </IconButton>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </div>

                              {detailsData?.variants &&
                              detailsData?.variants.length > 0 &&
                              Object.keys(groupedVariants).length === 0 ? (
                                <div className="color-name-block">
                                  <label>
                                    {lableVariation &&
                                    lableVariation !== undefined
                                      ? lableVariation.charAt(0).toUpperCase() +
                                        lableVariation.slice(1)
                                      : ""}{" "}
                                  </label>
                                  :
                                  <span>
                                    {" "}
                                    {colorVariation &&
                                    colorVariation !== undefined
                                      ? colorVariation
                                      : ""}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              {detailsData?.variants &&
                              detailsData?.variants.length > 0 &&
                              Object.keys(groupedVariants).length === 0 ? (
                                <div className="custom-img-tab cust-tabs-list">
                                  <Box>
                                    <Tabs
                                      value={lvalue}
                                      onChange={handleChangeTab}
                                      aria-label="image tabs"
                                      variant="scrollable"
                                      scrollButtons="auto"
                                      allowScrollButtonsMobile
                                      sx={{
                                        overflow: "auto",
                                        ".MuiTabs-scrollButtons:first-of-type":
                                          {
                                            cursor:
                                              lvalue === 1
                                                ? "not-allowed"
                                                : "pointer",
                                            opacity: lvalue === 1 ? 0.01 : 1,
                                          },
                                        ".MuiTabs-scrollButtons:last-of-type": {
                                          cursor:
                                            lvalue ===
                                            detailsData?.variants?.length - 1
                                              ? "not-allowed"
                                              : "pointer",
                                          opacity:
                                            lvalue ===
                                            detailsData?.variants?.length - 1
                                              ? 0.01
                                              : 1,
                                        },
                                      }}
                                    >
                                      {Array.from(
                                        Array(detailsData?.variants?.length)
                                      )?.map((_, index) => (
                                        <Tab
                                          label={smallImage[index]}
                                          {...a11yProps(index)}
                                          key={index}
                                          sx={{ p: 1 }}
                                        />
                                      ))}
                                    </Tabs>
                                  </Box>
                                </div>
                              ) : (
                                ""
                              )}
                              {(store === "ae_en" ||
                                store === "ae_ar" ||
                                store === "sa_en" ||
                                store === "sa_ar") &&
                              detailsData.price_range.minimum_price.final_price
                                ?.value <= 5000 ? (
                                <TabbyProductWidget
                                  amt={
                                    variantsDetails &&
                                    variantsDetails !== undefined
                                      ? variantsDetails?.price_range
                                          ?.minimum_price?.regular_price?.value
                                      : detailsData?.price_range?.minimum_price
                                          ?.final_price?.value
                                  }
                                  currency={
                                    detailsData?.price_range?.minimum_price
                                      ?.final_price?.currency
                                  }
                                  store={store}
                                  page="detail"
                                />
                              ) : (
                                ""
                              )}
                              <Typography
                                variant="body2"
                                component="ul"
                                className="prod-description"
                                sx={{ px: 0 }}
                              >
                                <div
                                  className={`description-text ${
                                    expanded ? "expanded" : ""
                                  }`}
                                  dangerouslySetInnerHTML={{
                                    __html: description,
                                  }}
                                />
                                {description && description.length > 4 * 90 ? (
                                  <span
                                    onClick={toggleExpanded}
                                    className="show-more-link"
                                  >
                                    {expanded
                                      ? `${t(
                                          "inner_pages.detail_page.read_less"
                                        )}`
                                      : `${t(
                                          "inner_pages.detail_page.read_more"
                                        )}`}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Typography>
                            </Stack>
                          </Box>
                        </Item>
                      </Grid>
                      <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                        <Item sx={{ boxShadow: 0, mb: 1 }}>
                          <Card variant="outlined" sx={{ borderRadius: "3px" }}>
                            <div className="details-right-content">
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  bgcolor: "background.paper",
                                }}
                              >
                                <ListItem
                                  style={{
                                    direction:
                                      store === "ae_ar" ||
                                      store === "sa_ar" ||
                                      store === "ar"
                                        ? "rtl"
                                        : "ltr",
                                    textAlign:
                                      store === "ae_ar" ||
                                      store === "sa_ar" ||
                                      store === "ar"
                                        ? "right"
                                        : "left",
                                  }}
                                >
                                  <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#fff" }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="38"
                                        height="38"
                                        viewBox="0 0 38 38"
                                        fill="none"
                                      >
                                        <path
                                          d="M25.1393 26.9738C23.8511 27.8152 22.3124 28.3044 20.657 28.3044C19.366 28.3044 18.1468 28.0069 17.0621 27.4775C15.7149 26.8201 14.5737 25.804 13.7634 24.5538C12.9317 23.2705 12.4487 21.7409 12.4487 20.096C12.4487 18.2417 13.0627 16.533 14.0988 15.159C15.5982 13.1706 17.9777 11.8877 20.657 11.8877C25.1904 11.8877 28.8654 15.5627 28.8654 20.096C28.8654 21.2592 28.6239 22.3641 28.189 23.3646C27.5458 24.8447 26.4782 26.0993 25.1393 26.9738Z"
                                          fill="#1082C2"
                                          stroke="#333848"
                                        />
                                        <path
                                          d="M16.4673 20.0294L19.4508 23.0622L25.0857 17.1299"
                                          stroke="white"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M6.08545 8.0957H20.6155C27.2429 8.0957 32.6156 13.4683 32.6156 20.0957C32.6156 26.723 27.2429 32.0958 20.6155 32.0958H16.7755"
                                          stroke="#333848"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M8.38427 5.1543L5.38428 8.09604L8.38427 11.5646"
                                          stroke="#333848"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M12.9543 32.0957H9.80713"
                                          stroke="#333848"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    className="list-content-title"
                                    primary={t(
                                      "inner_pages.detail_page.free-return"
                                    )}
                                    secondary={t(
                                      "inner_pages.detail_page.free-return-content"
                                    )}
                                  />
                                </ListItem>
                                <Divider />
                                <ListItem
                                  style={{
                                    direction:
                                      store === "ae_ar" ||
                                      store === "sa_ar" ||
                                      store === "ar"
                                        ? "rtl"
                                        : "ltr",
                                    textAlign:
                                      store === "ae_ar" ||
                                      store === "sa_ar" ||
                                      store === "ar"
                                        ? "right"
                                        : "left",
                                  }}
                                >
                                  <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#fff" }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="20"
                                        viewBox="0 0 32 20"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M22.0227 16.0797H20.8853V4.39551H25.9081L30.7539 8.48644V16.0797H29.8938"
                                          fill="#1082C2"
                                        />
                                        <path
                                          d="M22.0227 16.0797H20.8853V4.39551H25.9081L30.7539 8.48644V16.0797H29.8938"
                                          stroke="#404553"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M23.0942 6.729H25.6058L28.0093 8.77758V10.2343H23.0942V6.729Z"
                                          stroke="#404553"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M20.5148 1.71582H4.82324V15.8383H20.5148V1.71582Z"
                                          fill="#1082C2"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M7.77439 16.0794H5.0127V12.3114M5.0127 5.16554V1.47412H20.8857V16.0794H16.0553"
                                          fill="#1082C2"
                                        />
                                        <path
                                          d="M7.77442 16.8187C8.18265 16.8187 8.51371 16.4877 8.51371 16.0794C8.51371 15.6712 8.18265 15.3401 7.77442 15.3401V16.8187ZM5.01273 16.0794H4.27344C4.27344 16.4877 4.60449 16.8187 5.01273 16.8187V16.0794ZM5.75202 12.3114C5.75202 11.903 5.42111 11.5721 5.01273 11.5721C4.60449 11.5721 4.27344 11.903 4.27344 12.3114H5.75202ZM4.27344 5.16558C4.27344 5.57381 4.60449 5.90487 5.01273 5.90487C5.42096 5.90487 5.75202 5.57381 5.75202 5.16558H4.27344ZM5.01273 1.47415V0.734863C4.60449 0.734863 4.27344 1.06577 4.27344 1.47415H5.01273ZM20.8857 1.47415H21.625C21.625 1.06577 21.294 0.734863 20.8857 0.734863V1.47415ZM20.8857 16.0794V16.8187C21.294 16.8187 21.625 16.4877 21.625 16.0794H20.8857ZM16.0554 15.3401C15.6471 15.3401 15.3161 15.6712 15.3161 16.0794C15.3161 16.4877 15.6471 16.8187 16.0554 16.8187V15.3401ZM7.77442 15.3401H5.01273V16.8187H7.77442V15.3401ZM5.75202 16.0794V12.3114H4.27344V16.0794H5.75202ZM5.75202 5.16558V1.47415H4.27344V5.16558H5.75202ZM5.01273 2.21344H20.8857V0.734863H5.01273V2.21344ZM20.1464 1.47415V16.0794H21.625V1.47415H20.1464ZM20.8857 15.3401H16.0554V16.8187H20.8857V15.3401Z"
                                          fill="#404553"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M11.644 18.9998C12.9428 18.9998 13.9955 17.9536 13.9955 16.6631C13.9955 15.3724 12.9428 14.3262 11.644 14.3262C10.3454 14.3262 9.29248 15.3724 9.29248 16.6631C9.29248 17.9536 10.3454 18.9998 11.644 18.9998Z"
                                          fill="#1082C2"
                                          stroke="#404553"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M25.8579 18.9998C27.1567 18.9998 28.2094 17.9536 28.2094 16.6631C28.2094 15.3724 27.1567 14.3262 25.8579 14.3262C24.5591 14.3262 23.5063 15.3724 23.5063 16.6631C23.5063 17.9536 24.5591 18.9998 25.8579 18.9998Z"
                                          fill="#1082C2"
                                          stroke="#404553"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M2.07275 7.02344H9.35446"
                                          stroke="#404553"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M1.09961 9.55518H4.48912"
                                          stroke="#404553"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    className="list-content-title"
                                    primary={t(
                                      "inner_pages.detail_page.trusted-shipping"
                                    )}
                                    secondary={t(
                                      "inner_pages.detail_page.trusted-shipping-content"
                                    )}
                                  />
                                </ListItem>
                                <Divider />
                                <ListItem
                                  style={{
                                    direction:
                                      store === "ae_ar" ||
                                      store === "sa_ar" ||
                                      store === "ar"
                                        ? "rtl"
                                        : "ltr",
                                    textAlign:
                                      store === "ae_ar" ||
                                      store === "sa_ar" ||
                                      store === "ar"
                                        ? "right"
                                        : "left",
                                  }}
                                >
                                  <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#fff" }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                      >
                                        <path
                                          d="M23.2273 1.00049H5.89453V26.9997H23.2273V1.00049Z"
                                          fill="#1082C2"
                                          stroke="#404553"
                                        />
                                        <path
                                          d="M27.5605 26.9995H5.89453"
                                          stroke="#404553"
                                        />
                                        <path
                                          d="M9.50537 14.7224V10.3892"
                                          stroke="#404553"
                                        />
                                        <path
                                          d="M10.228 17.6562H0.898438V26.9998H10.228V17.6562Z"
                                          fill="#1082C2"
                                          stroke="#404553"
                                        />
                                        <path
                                          d="M14.631 20.4204H8.06104V27.0004H14.631V20.4204Z"
                                          fill="white"
                                          stroke="#404553"
                                        />
                                      </svg>
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    className="list-content-title"
                                    primary={t(
                                      "inner_pages.detail_page.contactless-delivery"
                                    )}
                                    secondary={t(
                                      "inner_pages.detail_page.your-delivery-left"
                                    )}
                                  />
                                </ListItem>
                              </List>
                            </div>
                          </Card>
                        </Item>
                        <Item sx={{ boxShadow: 0 }}>
                          <Card variant="outlined" sx={{ borderRadius: "3px" }}>
                            <div className="details-soldby-content">
                              <List
                                component="nav"
                                aria-label="main mailbox folders"
                                sx={{ pb: 0 }}
                              >
                                <ListItem alignItems="flex-start">
                                  <ListItemIcon>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="32"
                                      height="32"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.86719 11.0723H26.2733V26.758H5.86719V11.0723Z"
                                        fill="#00B4D7"
                                        stroke="#404553"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M27.4001 12.0154C27.4001 13.5389 26.1241 14.7739 24.5501 14.7739C23.4105 14.7739 22.4273 14.1267 21.9712 13.1913C21.8601 12.9632 21.5401 12.9632 21.4289 13.1913C20.9729 14.1267 19.9896 14.7739 18.8501 14.7739C17.7105 14.7739 16.7273 14.1267 16.2712 13.1913C16.1601 12.9632 15.8401 12.9632 15.7289 13.1913C15.2729 14.1267 14.2896 14.7739 13.1501 14.7739C12.0106 14.7739 11.0273 14.1267 10.5713 13.1913C10.4601 12.9632 10.1401 12.9632 10.029 13.1913C9.57291 14.1267 8.58962 14.7739 7.4501 14.7739C5.8761 14.7739 4.6001 13.5389 4.6001 12.0154L7.33027 5.60088C7.46123 5.37908 7.70491 5.24219 7.96884 5.24219H24.0313C24.2953 5.24219 24.5389 5.37908 24.6699 5.60088L27.4001 12.0154Z"
                                        fill="white"
                                        stroke="#404553"
                                      />
                                      <path
                                        d="M17.4438 26.7582V17.9482H22.4786V23.6736"
                                        stroke="#404553"
                                        stroke-linecap="round"
                                      />
                                      <path
                                        d="M9.35059 19.1523H14.0173"
                                        stroke="#404553"
                                        stroke-linecap="round"
                                      />
                                    </svg>
                                  </ListItemIcon>
                                  <ListItemText
                                    style={{
                                      direction:
                                        store === "ae_ar" ||
                                        store === "sa_ar" ||
                                        store === "ar"
                                          ? "rtl"
                                          : "ltr",
                                      textAlign:
                                        store === "ae_ar" ||
                                        store === "sa_ar" ||
                                        store === "ar"
                                          ? "right"
                                          : "left",
                                    }}
                                    // primary="Sold by : Ashcoffee.in"
                                    primary={
                                      <Typography
                                        dangerouslySetInnerHTML={{
                                          __html: primarySellerHtml,
                                        }}
                                      />
                                    }
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          sx={{ display: "inline" }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                          <Chip
                                            color="success"
                                            icon={
                                              <StarOutlinedIcon
                                                style={{
                                                  fontSize: 13,
                                                  margin: "5px",
                                                }}
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 11,
                                                  margin: "3px",
                                                }}
                                              >
                                                4.5
                                              </span>
                                            }
                                            sx={{ height: 20 }}
                                          />
                                        </Typography>
                                        {`90% ${t(
                                          "inner_pages.detail_page.positive-ratings"
                                        )}`}
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                                <Divider />
                                <ListItem
                                  alignItems="center"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    py: 0,
                                  }}
                                >
                                  <ListItem
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ListItemAvatar>
                                      <img
                                        src={soldByThumbImg}
                                        alt=""
                                        style={{ width: "30%" }}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          sx={{
                                            display: "inline",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            color: "#5B5858",
                                          }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                          {t(
                                            "inner_pages.detail_page.great-recent-rating"
                                          )}
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                  <Divider orientation="vertical" flexItem />
                                  <ListItem
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ListItemText>
                                      <Typography
                                        sx={{
                                          fontFamily: "Poppins",
                                          fontSize: "30px",
                                          fontWeight: 700,
                                          lineHeight: "36px",
                                          letterSpacing: "0em",
                                          textAlign: "center",
                                          color: "#00B4D7",
                                        }}
                                      >
                                        {" "}
                                        80%{" "}
                                      </Typography>
                                    </ListItemText>
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            sx={{
                                              display: "inline",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                              color: "#5B5858",
                                            }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {t(
                                              "inner_pages.detail_page.product-as-described"
                                            )}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>
                                </ListItem>
                              </List>
                            </div>
                          </Card>
                        </Item>
                        {sellerDatas.length > 1 && (
                          <Item sx={{ boxShadow: 0 }}>
                            <Card
                              variant="outlined"
                              sx={{ borderRadius: "3px" }}
                            >
                              <div className="other-offers">
                                <List
                                  component="nav"
                                  aria-label="main mailbox folders"
                                  sx={{ pb: 0 }}
                                >
                                  <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.86719 11.0723H26.2733V26.758H5.86719V11.0723Z"
                                          fill="#00B4D7"
                                          stroke="#404553"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M27.4001 12.0154C27.4001 13.5389 26.1241 14.7739 24.5501 14.7739C23.4105 14.7739 22.4273 14.1267 21.9712 13.1913C21.8601 12.9632 21.5401 12.9632 21.4289 13.1913C20.9729 14.1267 19.9896 14.7739 18.8501 14.7739C17.7105 14.7739 16.7273 14.1267 16.2712 13.1913C16.1601 12.9632 15.8401 12.9632 15.7289 13.1913C15.2729 14.1267 14.2896 14.7739 13.1501 14.7739C12.0106 14.7739 11.0273 14.1267 10.5713 13.1913C10.4601 12.9632 10.1401 12.9632 10.029 13.1913C9.57291 14.1267 8.58962 14.7739 7.4501 14.7739C5.8761 14.7739 4.6001 13.5389 4.6001 12.0154L7.33027 5.60088C7.46123 5.37908 7.70491 5.24219 7.96884 5.24219H24.0313C24.2953 5.24219 24.5389 5.37908 24.6699 5.60088L27.4001 12.0154Z"
                                          fill="white"
                                          stroke="#404553"
                                        />
                                        <path
                                          d="M17.4438 26.7582V17.9482H22.4786V23.6736"
                                          stroke="#404553"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          d="M9.35059 19.1523H14.0173"
                                          stroke="#404553"
                                          stroke-linecap="round"
                                        />
                                      </svg>
                                    </ListItemIcon>
                                    <ListItemText
                                      // primary="Sold by : Ashcoffee.in"
                                      primary={
                                        <Typography
                                          sx={{
                                            display: "inline",
                                            fontWeight: "400",
                                          }}
                                          component="span"
                                          variant="body2"
                                          color="#000000"
                                        >
                                          {sellerDatas.length} more offers from
                                          other sellers
                                        </Typography>
                                      }
                                      secondary={
                                        <React.Fragment>
                                          <Typography
                                            sx={{
                                              display: "inline",
                                              fontWeight: "500",
                                            }}
                                            component="span"
                                            variant="body2"
                                            color="#000000"
                                          >
                                            {sellerDatas[0]?.currency}{" "}
                                            {sellerDatas[0]?.price}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <Typography
                                      sx={{
                                        fontWeight: "400",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        display: "inline",
                                        backgroundColor: "transparent",
                                        border: "transparent",
                                      }}
                                      color="#00B4D7"
                                      component="button"
                                      onClick={toggleProductOffers}
                                    >
                                      {sellerDatas.length > 1 &&
                                        "View all Offers"}
                                    </Typography>
                                  </ListItem>
                                  {showProductOffers && (
                                    <Backdrop>
                                      <ProductOffersPopUp
                                        onClose={closeProductOffers}
                                        sellerDatas={sellerDatas}
                                        onSellerIdChange={handleSellerIdChange}
                                        handleAddToCart={handleAddToCart}
                                      />
                                    </Backdrop>
                                  )}
                                </List>
                              </div>
                            </Card>
                          </Item>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </div>
              <div style={{ backgroundColor: "#fff", marginBottom: "20px" }}>
                <Container maxWidth={false}>
                  <Box sx={{ pt: 5, pb: 5 }}>
                    <Grid
                      container
                      style={{
                        direction:
                          store === "ae_ar" ||
                          store === "sa_ar" ||
                          store === "ar"
                            ? "rtl"
                            : "ltr",
                        textAlign:
                          store === "ae_ar" ||
                          store === "sa_ar" ||
                          store === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      <Grid
                        item
                        xl={7}
                        lg={7}
                        md={6}
                        sm={12}
                        xs={12}
                        className="dsc-block"
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h5"
                          sx={{ px: 1, fontWeight: "700" }}
                          style={{
                            direction:
                              store === "ae_ar" ||
                              store === "sa_ar" ||
                              store === "ar"
                                ? "rtl"
                                : "ltr",
                            textAlign:
                              store === "ae_ar" ||
                              store === "sa_ar" ||
                              store === "ar"
                                ? "right"
                                : "left",
                          }}
                        >
                          {t("inner_pages.detail_page.overview")}
                        </Typography>
                        <Divider />

                        <Box sx={{ mt: 2, px: 1 }}>
                          <div className="overview-box">
                            <Typography component="div">
                              <ul
                                className="overview-content"
                                style={{
                                  direction:
                                    store === "ae_ar" ||
                                    store === "sa_ar" ||
                                    store === "ar"
                                      ? "rtl"
                                      : "ltr",
                                  textAlign:
                                    store === "ae_ar" ||
                                    store === "sa_ar" ||
                                    store === "ar"
                                      ? "right"
                                      : "left",
                                }}
                              >
                                <li style={{ listStyle: "none" }}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: detailsData?.description?.html,
                                    }}
                                    style={{ whiteSpace: "initial" }}
                                  />
                                </li>
                              </ul>
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xl={5}
                        lg={5}
                        md={6}
                        sm={12}
                        xs={12}
                        className="dsc-block"
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h5"
                          sx={{ px: 1, fontWeight: "700" }}
                          style={{
                            direction:
                              store === "ae_ar" ||
                              store === "sa_ar" ||
                              store === "ar"
                                ? "rtl"
                                : "ltr",
                            textAlign:
                              store === "ae_ar" ||
                              store === "sa_ar" ||
                              store === "ar"
                                ? "right"
                                : "left",
                          }}
                        >
                          {t("inner_pages.detail_page.specification")}
                        </Typography>
                        <Divider />
                        <Box sx={{ mt: 2, px: 1 }}>
                          <TableContainer>
                            <Table
                              sx={{ minWidth: "100%" }}
                              aria-label="customized table"
                              className="specification-tbl"
                            >
                              <TableBody>
                                {detailsData?.attributes?.map(
                                  (row: any, index: any) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                      >
                                        {row.label}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.value}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        {detailsData?.files?.length > 0 && (
                          <>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h5"
                              sx={{ px: 1 }}
                              className="download-title"
                            >
                              {t("inner_pages.detail_page.download-pdf")}
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                              {detailsData?.files?.map(
                                (item: any, index: any) => (
                                  <div className="download-card" key={index}>
                                    <div className="download-card-info">
                                      <PictureAsPdfIcon />
                                      <Typography>
                                        {item.name.split(".").shift()}
                                      </Typography>
                                    </div>

                                    <div
                                      className="download-svg"
                                      onClick={() => downloadPdf(item)}
                                    >
                                      <FileDownloadOutlinedIcon />
                                    </div>
                                  </div>
                                )
                              )}
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </div>

              <div>
                <div
                  id="rating_card"
                  ref={ratingCardRef}
                  className="review-rating-start"
                  style={{
                    direction:
                      store === "ae_ar" || store === "sa_ar" || store === "ar"
                        ? "rtl"
                        : "ltr",
                    textAlign:
                      store === "ae_ar" || store === "sa_ar" || store === "ar"
                        ? "right"
                        : "left",
                  }}
                >
                  <Box>
                    <Grid container className="reviews-gaps">
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={5}
                        sm={12}
                        xs={12}
                        className="dsc-block review-start"
                        style={{
                          direction:
                            store === "ae_ar" ||
                            store === "sa_ar" ||
                            store === "ar"
                              ? "rtl"
                              : "ltr",
                          textAlign:
                            store === "ae_ar" ||
                            store === "sa_ar" ||
                            store === "ar"
                              ? "right"
                              : "left",
                          backgroundColor: "#fff",
                          marginBottom: "20px",
                          padding: "30px 0px",
                        }}
                      >
                        <Container maxWidth={false}>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h5"
                            sx={{ px: 1, mb: 2, fontWeight: "bold" }}
                            className="rating-title"
                            style={{
                              direction:
                                store === "ae_ar" ||
                                store === "sa_ar" ||
                                store === "ar"
                                  ? "rtl"
                                  : "ltr",
                              textAlign:
                                store === "ae_ar" ||
                                store === "sa_ar" ||
                                store === "ar"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            {t("inner_pages.detail_page.ratings")}
                          </Typography>
                          <div className="all-reviews">
                            <Box>
                              <Grid container>
                                <Grid item xs={12} md={4} lg={3}>
                                  <Typography
                                    variant="h3"
                                    className="total-title"
                                  >
                                    {detailsData?.reviews?.items.length
                                      ? finalAverageRating
                                      : 0}
                                    <StarIcon />
                                  </Typography>
                                  {/* <p>{`${detailsData?.rating_summary} ${t("inner_pages.detail_page.ratings")} & ${detailsData?.review_count} ${t("inner_pages.detail_page.reviews")}`}</p> */}
                                  <p>{`${detailsData?.review_count} ${t(
                                    "inner_pages.detail_page.reviews"
                                  )}`}</p>
                                </Grid>
                                <Grid item xs={12} md={8} lg={9}>
                                  <div className="progressbar-list">
                                    <List>
                                      {detailsData?.reviews?.items.length ? (
                                        Object.keys(ratingCounts)
                                          .reverse()
                                          ?.map((ratingValue: any) => (
                                            <ListItem
                                              key={ratingValue}
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                              }}
                                            >
                                              <Typography
                                                variant="h3"
                                                className="total-title"
                                              >
                                                {ratingValue} <StarIcon />
                                              </Typography>
                                              <BorderLinearProgress
                                                variant="determinate"
                                                value={
                                                  (ratingCounts[ratingValue] /
                                                    reviewsArr.length) *
                                                  100
                                                }
                                                sx={{ width: "100%" }}
                                              />
                                              <Typography variant="h6">
                                                {ratingCounts[ratingValue]}
                                              </Typography>
                                            </ListItem>
                                          ))
                                      ) : (
                                        <List>
                                          <ListItem
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            <Typography
                                              variant="h3"
                                              className="total-title"
                                            >
                                              5 <StarIcon />
                                            </Typography>
                                            <BorderLinearProgress
                                              variant="determinate"
                                              value={0}
                                              sx={{ width: "100%" }}
                                            />
                                            <Typography variant="h6">
                                              0
                                            </Typography>
                                          </ListItem>
                                          <ListItem
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            <Typography
                                              variant="h3"
                                              className="total-title"
                                            >
                                              4 <StarIcon />
                                            </Typography>
                                            <BorderLinearProgress
                                              variant="determinate"
                                              value={0}
                                              sx={{ width: "100%" }}
                                            />
                                            <Typography variant="h6">
                                              0
                                            </Typography>
                                          </ListItem>
                                          <ListItem
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            <Typography
                                              variant="h3"
                                              className="total-title"
                                            >
                                              3 <StarIcon />
                                            </Typography>
                                            <BorderLinearProgress
                                              variant="determinate"
                                              value={0}
                                              sx={{ width: "100%" }}
                                            />
                                            <Typography variant="h6">
                                              0
                                            </Typography>
                                          </ListItem>
                                          <ListItem
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            <Typography
                                              variant="h3"
                                              className="total-title"
                                            >
                                              2 <StarIcon />
                                            </Typography>
                                            <BorderLinearProgress
                                              variant="determinate"
                                              value={0}
                                              sx={{
                                                width: "100%",
                                                backgroundColor: "#FD6847",
                                              }}
                                            />
                                            <Typography variant="h6">
                                              0
                                            </Typography>
                                          </ListItem>
                                          <ListItem
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            <Typography
                                              variant="h3"
                                              className="total-title"
                                            >
                                              1 <StarIcon />
                                            </Typography>
                                            <BorderLinearProgress
                                              variant="determinate"
                                              value={0}
                                              sx={{ width: "100%" }}
                                            />
                                            <Typography variant="h6">
                                              0
                                            </Typography>
                                          </ListItem>
                                        </List>
                                      )}
                                    </List>
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        </Container>
                      </Grid>
                      <Grid
                        item
                        xl={8}
                        lg={8}
                        md={7}
                        sm={12}
                        xs={12}
                        className="dsc-block reviews-details "
                        style={{
                          backgroundColor: "#fff",
                          marginBottom: "20px",
                          padding: "30px 0px",
                        }}
                      >
                        <Container maxWidth={false}>
                          <div className="customer-reviews-wrap">
                            <div>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h5"
                                sx={{ px: 1 }}
                              >
                                {t("inner_pages.detail_page.reviews")}
                              </Typography>
                            </div>
                            <button
                              className="review-btn"
                              onClick={handleAddReviewOpen}
                              style={{ cursor: "pointer" }}
                            >
                              {t(
                                "inner_pages.detail_page.add-ratings-and-reviews"
                              )}
                            </button>
                            {showAddReviewModal && (
                              <AddReviewModal
                                open={showAddReviewModal}
                                onClose={handleAddReviewClose}
                                onSubmit={handleFormSubmit}
                                detailsData={detailsData}
                                productName={extractProductName()}
                                productImage={extractProductImage()}
                                ratingMetadata={ratingMetadata()}
                                avgRating={finalAverageRating}
                                ratingMetaDataId={ratingMetaDataId}
                              />
                            )}
                          </div>
                          {/* All Images from reviews */}
                          <div className="user-review-main">
                            {detailsData?.reviews?.items?.map((item: any) => {
                              const finalRating = item?.average_rating / 20;
                              const ratingClassName =
                                finalRating >= 3
                                  ? "green"
                                  : finalRating >= 2
                                  ? "orange"
                                  : "red";
                              return (
                                <>
                                  <div className="user-review-wrap">
                                    <div className="rating-star-tag">
                                      <span
                                        className={`user-rating-star ${ratingClassName}`}
                                      >
                                        {finalRating} <StarIcon />
                                      </span>
                                      <span className="rating-tag">
                                        {item?.summary}
                                      </span>
                                    </div>
                                    <div className="user-review">
                                      <p className="description">
                                        {item?.text}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                          {detailsData?.reviews?.items.length > 5 && (
                            <Typography
                              component={Button}
                              sx={{
                                textDecoration: "underline",
                                textTransform: "none",
                                fontSize: "18px",
                                fontWeight: "600",
                                fontFamily: "poppins",
                                color: "#00B4D8",
                                "&:hover": { background: "transparent" },
                              }}
                            >
                              {t("inner_pages.detail_page.view-all-reviews")}
                            </Typography>
                          )}
                        </Container>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>

              {RelatedProductData?.length > 0 && (
                <div className="similar-items-section">
                  <div className="section-title">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="span"
                      color="text.dark"
                    >
                      {t("inner_pages.detail_page.similar-products")}
                    </Typography>
                  </div>

                  <div className="similiar-products jeb-cust-slide">
                    {relatedProductLoader || detailsloader ? (
                      <Carousel
                        responsive={responsive}
                        autoPlay={false}
                        autoPlaySpeed={2000}
                        swipeable={true}
                        centerMode={false}
                        arrows={true}
                        infinite={true}
                        ssr={true}
                        showDots={false}
                        slidesToSlide={1}
                        className="top-seller-grid-slider"
                      >
                        {skeletonArr?.map((ele, index) => {
                          return (
                            <Card
                              key={index}
                              sx={{
                                maxWidth: "100%",
                                boxShadow: 0,
                                border: "1px solid #e1e1e1",
                                margin: {
                                  xs: "0px 5px",
                                  md: "0px 6px",
                                  lg: "0px 8px",
                                  xl: "0px 10px",
                                },
                              }}
                            >
                              <div key={`skeleton-${index}`}>
                                <div className="slider-img-box">
                                  <Skeleton
                                    variant="rectangular"
                                    sx={{
                                      width: "100%",
                                      height: {
                                        xs: "230px",
                                        sm: "300px",
                                        md: "300px",
                                        lg: "300px",
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </Card>
                          );
                        })}
                      </Carousel>
                    ) : (
                      <Carousel
                        responsive={responsive}
                        autoPlay={false}
                        autoPlaySpeed={2000}
                        swipeable={true}
                        centerMode={false}
                        arrows={true}
                        infinite={true}
                        ssr={true}
                        showDots={false}
                        slidesToSlide={1}
                        className="top-seller-grid-slider"
                      >
                        {RelatedProductData?.map((item: any, id: number) => (
                          <div key={id} className="similar-product-card">
                            <div
                              onClick={() =>
                                handleSimilarProduct(item?.url_key)
                              }
                            >
                              <Card
                                sx={{
                                  maxWidth: "100%",
                                  boxShadow: 0,
                                  border: "1px solid #e1e1e1",
                                  margin: {
                                    xs: "0px 5px",
                                    md: "0px 10px",
                                    lg: "0px 12px",
                                    xl: "0px 15px",
                                  },
                                  position: "relative",
                                }}
                              >
                                {item?.top_badge_left && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0px",
                                      left: "0px",
                                      zIndex: 10,
                                    }}
                                  >
                                    <img
                                      src={item?.top_badge_left}
                                      alt={
                                        item?.top_badge_left_title || "Badge"
                                      }
                                      style={{
                                        width: "100px",
                                        height: "auto",
                                        marginTop: "-28px",
                                      }}
                                    />
                                  </div>
                                )}
                                <CardActionArea>
                                  <div className="slider-img-box">
                                    <CardMedia
                                      component="img"
                                      height="140"
                                      image={item?.small_image?.small_url}
                                      alt={item?.small_image?.label}
                                    />
                                  </div>
                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                      color="text.dark"
                                      className="top-sell-title"
                                    >
                                      {item?.name}
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      color="text.dark"
                                      className="top-sell-amount"
                                    >
                                      {
                                        item?.price_range?.minimum_price
                                          ?.final_price?.currency
                                      }
                                      &nbsp;
                                      {
                                        item?.price_range?.minimum_price
                                          ?.final_price?.value
                                      }
                                    </Typography>

                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      className="tag"
                                      component="div"
                                      style={{
                                        direction:
                                          store === "ae_ar" ||
                                          store === "sa_ar" ||
                                          store === "ar"
                                            ? "rtl"
                                            : "ltr",
                                        textAlign:
                                          store === "ae_ar" ||
                                          store === "sa_ar" ||
                                          store === "ar"
                                            ? "right"
                                            : "left",
                                      }}
                                    >
                                      {t("main.inclusive_of_vat")}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <NotFoundPage />
          )}
        </div>
      )}
      {isOpenMobileDrawer && (
        <Addcart
          isOpenMobileDrawer={isOpenMobileDrawer}
          isToggleDrawer={toggleDrawer(false)}
        />
      )}
    </>
  );
}

interface BackdropProps {
  children: React.ReactNode;
}
const Backdrop: React.FC<BackdropProps> = ({ children }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        // alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

export default React.memo(DetailsPage);
