import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CountryContext } from "../../container/buyer/MainComponent";
import { useTranslation } from "react-i18next";
import GridUn from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Paper,
  Typography,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import cartImage from "../../assets/images/cart_img.png";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

interface ProductCardProps {
  filteredPro: any;
  index: any;
  setModalUrlKey: (urlKey: string) => void;
  setCartModalOpen: (open: boolean) => void;
  setProductData: any;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const ProductMainCard: React.FC<ProductCardProps> = ({
  filteredPro,
  setModalUrlKey,
  setCartModalOpen,
  index,
  setProductData,
}) => {
  const navigate = useNavigate();
  const { store } = useContext(CountryContext);
  const { t } = useTranslation();

  const updatedProductData = filteredPro?.map((product: any) => {
    const reviewsArr = product?.reviews?.items || [];
    const RegularPrice =
      product?.price_range?.minimum_price?.regular_price?.value;
    const SellingPrice =
      product?.price_range?.minimum_price?.final_price?.value;
    const AmountOff = Math.round(
      ((RegularPrice - SellingPrice) / RegularPrice) * 100
    );

    const ratingsOutOf5 = reviewsArr.map(
      (review: any) => (review?.average_rating ?? 0) / 20
    );

    const averageRating =
      ratingsOutOf5.length > 0
        ? ratingsOutOf5.reduce((total: any, rating: any) => total + rating, 0) /
          ratingsOutOf5.length
        : 0;

    const finalAverageRating = Math.round(averageRating * 10) / 10;

    return {
      ...product,
      finalAverageRating,
      AmountOff,
    };
  });
  
  return (
    <GridUn
      xs={6}
      sm={4}
      md={3}
      lg={2.4}
      xl={2.4}
      key={filteredPro[index]?.sku}
    >
      {/* <Link
                          to={`/${store}/${filteredPro[index]?.url_key}.html`}
                        > */}
      <Item sx={{ boxShadow: 0, height: "100%" }}>
        <Card
          className="jeb-cust-slide item-single slider-product-card"
          sx={{
            maxWidth: "100%",
            boxShadow: 0,
            border: "1px solid #e1e1e1",
            backgroundColor: "white",
            position: "relative",
            // height:"80%"
          }}
          onClick={() => {
            navigate(`/${store}/${updatedProductData[index]?.url_key}.html`, {
              state: { productData: filteredPro[index] },
            });
          }}
        >
          {updatedProductData[index]?.top_badge_left && (
            <div
              className="product-offer-tag"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                zIndex: 10,
              }}
            >
              <img
                src={updatedProductData[index]?.top_badge_left}
                alt={updatedProductData[index]?.top_badge_left_title || "Badge"}
                style={{
                  width: "100px",
                  height: "auto",
                  marginTop: "-28px",
                }}
                loading="lazy"
              />
            </div>
          )}
          <div
            className="slider-img-box"
            style={{
              position: "relative",
              height: "auto",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <CardMedia
              component="img"
              alt={updatedProductData[index]?.image?.__typenamel}
              height="100%"
              image={updatedProductData[index]?.image?.small_url}
              loading="lazy"
            />
          </div>
          <CardContent
            sx={{
              textAlign: "start",
              backgroundColor: "#fff",
              paddingTop: "0px",
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color="text.dark"
              className="top-sell-title"
            >
              {updatedProductData[index].name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              <Box
                // sx={{
                //   display: "flex",
                //   gap: {
                //     lg: "7px",
                //     md: "3px",
                //     xs: "0px",
                //     sm: "0px",
                //   },
                //   flexWrap: "wrap",
                // }}
                 className = "price-box"
              >
                <Box>
                  <Typography
                    gutterBottom
                    variant="h5"
                    color="text.dark"
                    className="top-sell-amount"
                  >
                    {
                      updatedProductData[index]?.price_range?.minimum_price
                        ?.final_price?.currency
                    }
                    &nbsp;
                    {
                      updatedProductData[index]?.price_range?.minimum_price
                        ?.final_price?.value
                    }
                  </Typography>
                </Box>
                <Box>
                  {updatedProductData[index]?.AmountOff > 0 && (
                    <Typography
                      gutterBottom
                      variant="h6"
                      color="text.secondary"
                      component="del"
                      className="top-actual_amount"
                    >
                      {
                        updatedProductData[index]?.price_range?.minimum_price
                          ?.regular_price?.currency
                      }{" "}
                      {
                        updatedProductData[index]?.price_range?.minimum_price
                          ?.regular_price?.value
                      }
                    </Typography>
                  )}
                </Box>
                <Box>
                  {updatedProductData[index]?.AmountOff > 0 && (
                    <Typography
                      gutterBottom
                      // variant="h6"
                      // color="text.secondary"
                      className="sell-percentage"
                    >
                      {
                       updatedProductData[index]?.AmountOff
                      }{" "}
                      % {t("inner_pages.domestic_machine.percentage_off")}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "2px",
                  flexWrap: "wrap",
                  // mb:"5px"
                }}
              >
                {updatedProductData[index]?.finalAverageRating >= 0 && (
                  <>
                    <Box
                      sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                    >
                      <Chip
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "12px",
                            }}
                          >
                            {updatedProductData[index]?.finalAverageRating}
                            <StarOutlinedIcon
                              className="starIcon"
                              style={{
                                marginLeft: "4px",
                                width: "15px",
                              }}
                            />
                          </div>
                        }
                        color="success"
                        size="small"
                        sx={{
                          backgroundColor: "#1FAF38",
                        }}
                      />
                      <span
                        style={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "300",
                          fontFamily: "poppins",
                        }}
                      >
                        {updatedProductData[index]?.review_count}{" "}
                        {t("main.review")}
                      </span>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: "10px",
              alignItems: "center",
              marginTop: "auto",
            }}
          >
            <Box>
              {updatedProductData[index]?.configurable_options && (
                <div className="color-switch-list">
                  {updatedProductData[
                    index
                  ]?.configurable_options[0]?.values?.map(
                    (item: any, index: any) => {
                      return (
                        <Button size="small" key={index}>
                          <div
                            className="color-switch"
                            style={{
                              backgroundColor: item?.swatch_data?.value,
                              border: "1px solid #e1e1e1",
                            }}
                          ></div>
                        </Button>
                      );
                    }
                  )}
                </div>
              )}
            </Box>
            <Box>
              <img
                src={cartImage}
                alt="cart"
                style={{
                  border: "1px solid #EFEDED",
                  borderRadius: "4px",
                  padding: "5px",
                  backgroundColor: "white",
                  height: "37px",
                  cursor: "pointer",
                  boxShadow: "0 4px 6px #EFEDED",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setCartModalOpen(true);
                  setModalUrlKey(updatedProductData[index]?.url_key);
                  setProductData(filteredPro[index]);
                }}
                loading="lazy"
              />
            </Box>
          </Box>
        </Card>
      </Item>
      {/* </Link> */}
    </GridUn>
  );
};

export default ProductMainCard;
